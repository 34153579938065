import './App.css';
//import { useScript } from 'use-script';
import useScript from 'react-script-hook';


function App() {
  const [loading, error] = useScript({
    src: '/esp-web-tools/install-button.js?module',
  })

  if (loading || error) return null;
  return (
    <div className="App">
      <h2>Gravvio Tools</h2>
      <p>In order to reset a firmware please:</p>
      <p>1. Connect your controller to USB</p>
      <p>2. Click "Connect", select a Serial port and click "Update"</p>
      <p>3. While showing "Preparing installation..." push the "Boot" button and wait until "Erasing" shows up</p>
      <p>4. To change WI-FI access point click "Change WI-FI"</p>
      <esp-web-install-button manifest="esp-manifest.json"></esp-web-install-button>
    </div>
  );
}

export default App;
